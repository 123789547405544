import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styled Components for styling
const KamisheniWrapper = styled.div`
  position: relative;
  height: auto;
  width: 100%; /* Full width */
  background-image:
    repeating-linear-gradient(45deg,
        rgba(0, 64, 128, 0.1) 0,
        rgba(0, 64, 128, 0.1) 1px,
        transparent 1px,
        transparent 10px),
    repeating-linear-gradient(135deg,
        rgba(0, 64, 128, 0.1) 0,
        rgba(0, 64, 128, 0.1) 1px,
        transparent 1px,
        transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #004080;
  margin-bottom: 2rem;
  letter-spacing: 1.5px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
`;

const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 1rem 0;
  padding: 2rem;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
`;

const ImageRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 2rem;
  width: 100%;
  justify-items: center; /* Center items horizontally within grid cells */
  margin-bottom: 2rem;
`;

const ImageItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: #004080;

  img {
    width: 100%;
    max-width: 250px; /* Base max-width */
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
  }

  span {
    margin-top: 0.3rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  /* Responsive adjustments */
  @media (max-width: 1024px) {
    img {
      max-width: 200px; /* Slightly smaller on medium screens */
    }
    span {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    img {
      max-width: 150px; /* Smaller on tablets */
    }
    span {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 480px) {
    img {
      max-width: 100px; /* Smallest on phones */
    }
    span {
      font-size: 0.7rem;
    }
  }
`;

const Kamisheni = () => {
  const [kamisheniData, setKamisheniData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetch('https://polisi.go.tz:8000/api/kamisheni/')
      .then((response) => response.json())
      .then((data) => {
        const formattedData = data.map((item) => ({
          ...item,
          imgSrc: `https://polisi.go.tz:8000${item.image}`,
        }));
        setKamisheniData(formattedData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <KamisheniWrapper>
      <Container>
        {/* Title with translation */}
        <Title>{t('kamisheniTitle')}</Title>

        {/* Image Grid */}
        <ImageRow>
          {kamisheniData.slice(0, 8).map((item, index) => ( // Limit to 8 items for 4x2 grid
            <ImageItem
              to={`/kamisheni/${item.id}`} // Link to specific kamisheni page using its ID
              key={index}
            >
              <img src={item.imgSrc} alt={item.kamisheni_name} />
              <span>{item.kamisheni_name}</span>
            </ImageItem>
          ))}
        </ImageRow>
      </Container>
    </KamisheniWrapper>
  );
};

export default Kamisheni;







import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// Styled Components for Habari page layout
const Section = styled.section`
  position: relative;
  height: auto;
   width: 100%; /* Full width */
  background-image:
    repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

const HabariWrapper = styled.div`
    width: 100%; /* Full width */
  margin: 0 auto;
  background-color: #f4f7fb;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const Content = styled.div`
  font-size: 1.2rem;
  color: #333;
  line-height: 1.8;
`;

const NewsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 1024px) {
    gap: 1.5rem;
  }
`;

const NewsItem = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  transition: transform 0.3s ease;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    gap: 1rem;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

const NewsImage = styled.img`
  max-width: 350px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

const NewsTitle = styled.h2`
  font-size: 1.4rem;
  color: #004080;
  margin: 0;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const NewsDate = styled.p`
  font-size: 1rem;
  color: #777;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const NewsDescription = styled.p`
  font-size: 1.1rem;
  color: #555;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ReadMore = styled.span`
  font-size: 1.1rem;
  color: #004080;
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const PaginationIcon = styled.span`
  background-color: #004080;
  color: white;
  padding: 0.6rem;
  font-size: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 0 0.5rem;

  &:hover {
    background-color: #005bb5;
    transform: scale(1.1);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const PageNumber = styled.span`
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
  margin: 0 1rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Habari = () => {
  const [habariItems, setHabariItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Show 3 items per page

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  const truncateDescription = (text) => {
    const words = text.split(' ');
    if (words.length > 50) {
      return words.slice(0, 50).join(' ') + '...';
    }
    return text;
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(habariItems.length / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const currentItems = habariItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Grouping items in chunks of 3
  const groupItemsInChunks = (items) => {
    const chunked = [];
    for (let i = 0; i < items.length; i += itemsPerPage) {
      chunked.push(items.slice(i, i + itemsPerPage));
    }
    return chunked;
  };

  const chunkedItems = groupItemsInChunks(habariItems);

  useEffect(() => {
    fetch('https://polisi.go.tz:8000/api/habari/latest/')
      .then((response) => response.json())
      .then((data) => {
        const formattedData = data.map((item) => ({
          ...item,
          imgSrc: `https://polisi.go.tz:8000${item.cover_image}`,
        }));
        setHabariItems(formattedData);
      })
      .catch((error) => console.error('Error fetching news data:', error));
  }, []);

  const renderPaginationNumbers = () => {
    const totalPages = chunkedItems.length;
    let numbers = [];

    // Always show first three pages
    for (let i = 1; i <= 3 && i <= totalPages; i++) {
      numbers.push(i);
    }

    // Show "..." if more pages exist between the first three and the last three
    if (totalPages > 6) {
      numbers.push('...');
    }

    // Always show last three pages
    for (let i = totalPages - 2; i <= totalPages; i++) {
      if (i > 3) numbers.push(i); // Avoid duplicates
    }

    return numbers.map((number, index) => {
      if (number === '...') {
        return (
          <span key={index} style={{ padding: '0.8rem 1.6rem', fontSize: '1.1rem', color: '#777' }}>...</span>
        );
      }
      return (
        <PageNumber key={index} onClick={() => paginate(number)}>
          {number}
        </PageNumber>
      );
    });
  };

  return (
    <Section>
      <HabariWrapper>
        <Title>Habari</Title>
        <Content>
          <NewsRow>
            {currentItems.map((news, index) => (
              <NewsItem key={index}>
                {news.imgSrc && <NewsImage src={news.imgSrc} alt={news.title} />}
                <NewsContent>
                  <NewsTitle>{news.title}</NewsTitle>
                  <NewsDate>{formatDate(news.date_posted)}</NewsDate>
                  <NewsDescription>
                    {truncateDescription(news.description)}
                  </NewsDescription>
                  <Link to={`/habari/${news.id}`}>
                    <ReadMore>Read More</ReadMore>
                  </Link>
                </NewsContent>
              </NewsItem>
            ))}
          </NewsRow>

          <PaginationWrapper>
            <PaginationIcon
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &#8592; {/* Left arrow icon */}
            </PaginationIcon>

            {renderPaginationNumbers()}

            <PaginationIcon
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === chunkedItems.length}
            >
              &#8594; {/* Right arrow icon */}
            </PaginationIcon>
          </PaginationWrapper>
        </Content>
      </HabariWrapper>
    </Section>
  );
};

export default Habari;


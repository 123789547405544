import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link for navigation
import bannerImage from '../assets/banner/background.png'; // Background
import leftBanner from '../assets/banner/left.png'; // Left logo
import rightBanner from '../assets/banner/right.png'; // Right logo
import { useTranslation } from 'react-i18next';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    background-color: #d8d8d8;
    margin: 0;
    font-family: 'Roboto Slab', serif;
    overflow-x: hidden;
  }
`;

// Main header wrapper with background image
const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  background: url(${bannerImage}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
  padding: 0 5vw;
`;

// Left banner, moved closer to the center
const LeftBanner = styled.img`
  position: absolute;
  left: 5vw;
  top: 0;
  height: 100%;
  width: auto;
  max-width: 18%;
  object-fit: contain;
  cursor: pointer; /* Indicate clickable */

  @media (max-width: 768px) {
    max-width: 14%;
    left: 3vw;
  }
`;

// Right banner, moved closer to the center
const RightBanner = styled.img`
  position: absolute;
  right: 5vw;
  top: 0;
  height: 100%;
  width: auto;
  max-width: 18%;
  object-fit: contain;
  cursor: pointer; /* Indicate clickable */

  @media (max-width: 768px) {
    max-width: 14%;
    right: 3vw;
  }
`;

const TextContainer = styled.div`
  max-width: 70%;
  text-align: center;
  padding: 0 1rem;
  
  @media (max-width: 768px) {
    max-width: 90vw;
  }
`;

const SmallText = styled.div`
  font-size: 0.85rem;
  letter-spacing: 1px;
  margin: 0.3rem 0;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const MediumText = styled.div`
  font-size: 0.95rem;
  letter-spacing: 1px;
  margin: 0.3rem 0;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const LargeText = styled.div`
  font-size: 1.6rem;
  letter-spacing: 1.5px;
  font-weight: bold;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

const Header = () => {
  const { t } = useTranslation();

  return (
    <>
      <GlobalStyle />
      <HeaderWrapper>
        {/* Left and Right banners wrapped in Link */}
        <Link to="/">
          <LeftBanner src={leftBanner} alt="Left Banner" />
        </Link>
        <Link to="/">
          <RightBanner src={rightBanner} alt="Right Banner" />
        </Link>

        {/* Centered text */}
        <TextContainer>
          <SmallText>{t('smallText')}</SmallText>
          <MediumText>{t('mediumText')}</MediumText>
          <LargeText>{t('largeText')}</LargeText>
        </TextContainer>
      </HeaderWrapper>
    </>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// Styled components for layout and design
const DetailWrapper = styled.div`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9fafc;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #0072ff;
  text-align: center;
  margin-bottom: 1rem;
`;

const CoverImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
`;

const DatePosted = styled.div`
  font-size: 1rem;
  color: #555;
`;

const RelatedImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
`;

const RelatedImageWrapper = styled.div`
  width: 220px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  padding: 1.2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
`;

const RelatedImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ImageTitle = styled.h4`
  font-size: 1.2rem;
  color: #333;
  margin: 0.8rem 0;
`;

const ImageDescription = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 0.5rem 0;
`;

const MatukioDetail = () => {
  const { id } = useParams(); // Get the Matukio ID from the URL
  const [matukio, setMatukio] = useState(null);

  useEffect(() => {
    // Fetch Matukio data from the API
    axios
      .get(`https://polisi.go.tz:8000/matukio/${id}/`)
      .then((response) => {
        setMatukio(response.data);
      })
      .catch((error) => {
        console.error('Error fetching Matukio details:', error);
      });
  }, [id]);

  if (!matukio) {
    return <div>Loading...</div>; // Loading state while fetching data
  }
  const relatedImages = matukio.related_images || [];

  return (
    <DetailWrapper>
      <ContentWrapper>
        <Title>{matukio.title}</Title>
        {matukio.cover_image && (
          <CoverImage
            src={`${matukio.cover_image}`}
            alt={matukio.title}
          />
        )}
        <Description>{matukio.description}</Description>
        <DatePosted>Date Posted: {new Date(matukio.date_posted).toLocaleDateString()}</DatePosted>
      </ContentWrapper>
      {relatedImages.length > 0 && (
        <RelatedImagesWrapper>
          {relatedImages.map((image) => (
            <RelatedImageWrapper key={image.id}>
              <RelatedImage
                src={image.image}
                alt={image.title}
              />
              <ImageTitle>{image.title}</ImageTitle>
              <ImageDescription>{image.description}</ImageDescription>
            </RelatedImageWrapper>
          ))}
        </RelatedImagesWrapper>
      )}
      {relatedImages.length === 0 && <p>No related images available.</p>}
    </DetailWrapper>
  );
};

export default MatukioDetail;

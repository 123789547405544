import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// Styled components for layout and design
const Section = styled.section`
  position: relative;
  height: auto;
  width: 100%; /* Full width */
  background-image:
    repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

const DetailWrapper = styled.div`
  width: 100%; /* Full width */
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9fafc;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 0.5s ease-in;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: center; /* Center alignment */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack image on top of description for small devices */
    gap: 1.5rem;
  }
`;

const ImageWrapper = styled.div`
  display: inline-flex; /* Changed to inline-flex to wrap image tightly */
  align-items: center; /* Center vertically */
  justify-content: center;
  /* Removed flex: 1 and max-width to match image size */
`;

const KamisheniImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 400px; /* Controls the size */
  max-height: 300px; /* Controls the size */
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  object-fit: contain; /* Shows full image */
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 300px; /* Smaller on tablets */
    max-height: 200px;
  }

  @media (max-width: 480px) {
    max-width: 200px; /* Even smaller on phones */
    max-height: 150px;
  }
`;

const TextWrapper = styled.div`
  flex: 2;
  font-size: 1.2rem;
  line-height: 1.6; /* Slightly tighter line spacing */
  color: #444;
  display: flex;
  flex-direction: column;
  gap: 0.8rem; /* Reduced spacing between elements */
`;

const Highlight = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #0056d2;
  text-transform: capitalize;
  margin: 0; /* Removed margin-bottom for tighter spacing */
`;

const Subheading = styled.h2`
  font-size: 1.3rem; /* Slightly smaller font */
  font-weight: 600;
  color: #666;
  margin: 0; /* Removed margins to minimize space */
`;

const Spinner = styled.div`
  margin: 3rem auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #0072ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Message = styled.div`
  text-align: center;
  font-size: 1.5rem;
  padding: 2rem;
`;

const ErrorIllustration = styled.img`
  max-width: 300px;
  margin: 0 auto 1rem;
`;

const KamisheniDetail = () => {
  const { id } = useParams();
  const [kamisheni, setKamisheni] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
      axios.get(`https://polisi.go.tz:8000/kamisheni/${id}/`)
      .then((response) => {
        setKamisheni(response.data);
        setError(false);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return (
      <DetailWrapper>
        <Spinner />
        <Message>Loading Kamisheni details...</Message>
      </DetailWrapper>
    );
  }

  if (error) {
    return (
      <DetailWrapper>
        <ErrorIllustration src="https://via.placeholder.com/300?text=Error" alt="Error" />
        <Message>Failed to fetch Kamisheni details. Please try again later.</Message>
      </DetailWrapper>
    );
  }

  return (
    <Section>
      <DetailWrapper>
        <ContentWrapper>
          <ImageWrapper>
            <KamisheniImage src={kamisheni.image} alt={kamisheni.kamisheni_name} />
          </ImageWrapper>

          <TextWrapper>
            <Highlight>{kamisheni.kamisheni_name}</Highlight>
            <Subheading>Kamishina: {kamisheni.kamishina_name}</Subheading>
            <p>{kamisheni.kamisheni_description}</p>
          </TextWrapper>
        </ContentWrapper>
      </DetailWrapper>
    </Section>
  );
};

export default KamisheniDetail;



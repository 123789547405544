import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Section = styled.section`
  position: relative;
  height: auto;
  max-width: 1600px;
  background-image: 
    repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

const DetailWrapper = styled.div`
  max-width: 1440px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9fafc;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 0.5s ease-in;
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #004080;
  text-align: center;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Ensures 4 items per row */
  gap: 10px;
  width: 100%;
  max-width: 1440px;
  margin-top: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Adjusts to 2 columns on smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* Stacks into 1 column on mobile */
  }
`;

const Card = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &:hover {
    transform: translateY(-5px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 6px;
  color: #333;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 8px;
`;

const VyeoVyaPolisi = () => {
  const [vyeoList, setVyeoList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://polisi.go.tz:8000/vyeo/')
      .then((response) => response.json())
      .then((data) => {
        setVyeoList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching Vyeo data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Section>
      <DetailWrapper>
        <Heading>Vyeo vya Polisi</Heading>
        {vyeoList.length > 0 ? (
          <CardsContainer>
            {vyeoList.map((cheo) => (
              <Card key={cheo.id}>
                {cheo.image && <CardImage src={`https://polisi.go.tz:8000${cheo.image}`} alt={cheo.title} />}
                <CardTitle>{cheo.title}</CardTitle>
                <CardDescription>{cheo.description}</CardDescription>
              </Card>
            ))}
          </CardsContainer>
        ) : (
          <p style={{ textAlign: 'center', color: '#555', fontSize: '1.2rem' }}>Hakuna data ya kuonyesha kwa sasa.</p>
        )}
      </DetailWrapper>
    </Section>
  );
};

export default VyeoVyaPolisi;

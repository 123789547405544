import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Styled components for layout and design
const Section = styled.section`
  position: relative;
  height: auto;
  max-width: 1600px;
  background-image: 
    repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

const DetailWrapper = styled.div`
  max-width: 1440px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9fafc;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 0.5s ease-in;
`;

const Heading = styled.h1`
  font-size: 2rem; /* Adjusted for responsiveness */
  font-weight: bold;
  margin-bottom: 1rem;
  color: #004080;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.6rem; /* Smaller font size for mobile devices */
  }
`;

const Paragraph = styled.p`
  font-size: 1rem; /* Adjusted for readability */
  line-height: 1.5;
  margin-bottom: 1rem;
  color: #555;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller font size for mobile devices */
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 1440px;
  margin-top: 20px;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on tablets */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* 1 column on mobile screens */
  }
`;

const Card = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  max-width: 300px;
  margin: 0 auto;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 6px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller font size on smaller screens */
  }
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller font size on smaller screens */
  }
`;

const WakuuWaPolisiWaliopita = () => {
  const [wakuuData, setWakuuData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://polisi.go.tz:8000/wakuu/')  // Your API endpoint
      .then((response) => response.json())
      .then((data) => {
        setWakuuData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Section>
      <DetailWrapper>
        <Heading>Wakuu wa Polisi Waliopita</Heading>
        <Paragraph>
          Orodha ya Wakuu wa Polisi waliowahi kuhudumu nchini na kuchangia maendeleo ya Jeshi la Polisi:
        </Paragraph>
        <CardsContainer>
          {wakuuData.map((wakuu, index) => (
            <Card key={index}>
              <div>
                {wakuu.image && (
                  <CardImage
                    src={`https://polisi.go.tz:8000${wakuu.image}`}
                    alt={wakuu.title}
                  />
                )}
              </div>
              <div>
                <CardTitle>{wakuu.title}</CardTitle>
                <CardDescription>{wakuu.description}</CardDescription>
              </div>
            </Card>
          ))}
        </CardsContainer>
      </DetailWrapper>
    </Section>
  );
};

export default WakuuWaPolisiWaliopita;

import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// Wrapper for the Dharura component with background image and overlay
const DharuraWrapper = styled.div`
  position: relative;
  width: 100%; /* Full width */
  background: url('/path/to/your/background-image.jpg') center center / cover no-repeat;
  display: flex;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
  margin: auto;

  /* Background overlay for better readability */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 64, 128, 0.7); /* Dark blue overlay with transparency */
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    padding: 0;
  }
`;

// Container for centering content with fixed width, positioned above overlay
const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Title styling
const Title = styled.h2`
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

// Toggle button styling for mobile view
const ToggleButton = styled.button`
  background-color: #004080;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0066cc;
  }

  @media (min-width: 769px) {
    display: none; /* Hide button on larger screens */
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// Row for the items, set to wrap for responsiveness
const ItemRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

// Individual item with responsive styling, reduced size
const RectangleItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;  /* Reduced width */
  height: 80px;  /* Reduced height */
  background: linear-gradient(145deg, #0066cc, #004080);
  color: #ffffff;
  border-radius: 8px;
  padding: 0.5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    width: 120px;  /* Even smaller width for mobile */
    height: 70px;  /* Even smaller height for mobile */
  }
`;

// Emergency label styling, adjusting font size based on text length
const ItemLabel = styled.span`
  font-size: clamp(0.7rem, 1.2vw, 0.9rem);  /* Reduced font size */
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

// Emergency number styling, enlarged for emphasis but smaller
const EmergencyNumber = styled.div`
  font-size: clamp(0.9rem, 1.8vw, 1.2rem);  /* Reduced font size */
  font-weight: bold;
`;

const Dharura = () => {
  const { t } = useTranslation();

  // Fetch translations for emergency numbers and title
  const emergencyData = t("emergencyNumbers.numbers", { returnObjects: true });
  const title = t("emergencyNumbers.title");

  return (
    <DharuraWrapper>
      <ContentContainer>
        <Title>{title}</Title>
        
        {/* Emergency numbers */}
        <ItemRow>
          {emergencyData.map((item, index) => (
            <RectangleItem key={index}>
              <ItemLabel>{item.label}</ItemLabel>
              <EmergencyNumber>{item.number}</EmergencyNumber>
            </RectangleItem>
          ))}
        </ItemRow>
      </ContentContainer>
    </DharuraWrapper>
  );
};

export default Dharura;

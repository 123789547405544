import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBars, FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavbarWrapper = styled.section`
  background: linear-gradient(to right, #00c6ff, #0072ff);
  color: #fff;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
 width: 100%; /* Full width */
  margin: auto;
  position: sticky;
  top: ${({ isScrolled }) => (isScrolled ? '30px' : '75px')}; /* Adjusted top on scroll */
  z-index: 1000;
 

  @media (max-width: 768px) {
    height: 35px;
    padding: 0;
    top: 0; /* Sticky navbar on top */
  }
`;

const NavItems = styled.nav`
  display: flex;
  gap: 2rem;
  font-size: 1rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #00c6ff, #0072ff);
    z-index: 1;
    gap: 1rem;
    padding: 1rem 0;
  }
`;

const NavItem = styled.div`
  position: relative;

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  transition: background 0.3s, color 0.3s, opacity 0.3s ease-out;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;

  &:hover {
    background: #004080;
    height: 35px;
    color: #fff;
    opacity: 0.8;
  }

  &:not(:hover) {
    opacity: 1;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

const Icon = styled(FaChevronDown)`
  margin-left: 0.5rem;
  transition: color 0.3s;

  ${NavLink}:hover & {
    color: #ffdd57;
  }
`;

const DropdownMenu = styled.div`
  display: block;
  position: absolute;
  background: #0072ff;
  border-radius: 5px;
  padding: 0.5rem 0;
  z-index: 1;
  left: 0;
  width: max-content;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s 0.3s;
  top: 60px;

  @media (max-width: 768px) {
    top: 40px;
  }

  ${NavItem}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const DropdownItem = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  transition: background 0.3s, padding 0.3s, font-size 0.3s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    background: #004080;
    border-bottom: 1px solid #ffdd57;
  }

  @media (max-width: 768px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 480px) {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;

  @media (min-width: 769px) {
    display: none;
  }
`;

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [kamisheniList, setKamisheniList] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false); // Track scroll position
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get('https://polisi.go.tz:8000/api/kamisheni/')
      .then((response) => {
        setKamisheniList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    // Add scroll event listener
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <NavbarWrapper isScrolled={isScrolled}>
      <ToggleButton onClick={() => setMenuOpen(!menuOpen)}>
        <FaBars />
      </ToggleButton>
      <NavItems isOpen={menuOpen}>
        <NavItem>
          <NavLink to="/" onClick={handleLinkClick}>
            {t('navbar.home')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="#">
            {t('navbar.about')}
            <Icon />
          </NavLink>
          <DropdownMenu>
            <DropdownItem to="/managements" onClick={handleLinkClick}>
              {t('navbar.police_force')}
            </DropdownItem>
            <DropdownItem to="/dhamira" onClick={handleLinkClick}>
              {t('navbar.goals_visions')}
            </DropdownItem>
            <DropdownItem to="/muundo" onClick={handleLinkClick}>
              {t('navbar.structure')}
            </DropdownItem>
            <DropdownItem to="/vyeo" onClick={handleLinkClick}>
              {t('navbar.ranks')}
            </DropdownItem>
            <DropdownItem to="/wakuu" onClick={handleLinkClick}>
              {t('navbar.past_leaders')}
            </DropdownItem>
          </DropdownMenu>
        </NavItem>
        <NavItem>
          <NavLink to="/habari" onClick={handleLinkClick}>
            {t('navbar.news')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="#">
            {t('navbar.commissions')}
            <Icon />
          </NavLink>
          <DropdownMenu>
            {kamisheniList.length > 0 ? (
              kamisheniList.map((kamisheni) => (
                <DropdownItem key={kamisheni.id} to={`/kamisheni/${kamisheni.id}`} onClick={handleLinkClick}>
                  {kamisheni.kamisheni_name}
                </DropdownItem>
              ))
            ) : (
              <DropdownItem to="#" onClick={(e) => e.preventDefault()}>
                {t('navbar.no_commissions')}
              </DropdownItem>
            )}
          </DropdownMenu>
        </NavItem>
        <NavItem>
          <NavLink to="/jarida" onClick={handleLinkClick}>
            {t('navbar.publications')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/ajira" onClick={handleLinkClick}>
            {t('navbar.careers')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/mawasiliano" onClick={handleLinkClick}>
            {t('navbar.contacts')}
          </NavLink>
        </NavItem>
      </NavItems>
    </NavbarWrapper>
  );
}

export default Navbar;


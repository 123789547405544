import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// Styled components for layout and design
const DetailWrapper = styled.div`
  max-width: 1200px;
  margin: 3rem auto;
  padding: 3rem;
  background-color: #f9fafc;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TitleWrapper = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.h2`
  font-size: 2.4rem;
  color: #004080;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 400px;  // Ensure the image does not grow too large
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.7;
  color: #555;
  text-align: justify;
  margin-top: 1rem;
  flex-grow: 1;  // Ensure description takes up available space
`;

const DateWrapper = styled.div`
  font-size: 1rem;
  color: #888;
  text-align: left;
  margin-top: 1rem;
  flex-basis: 100%;
`;

const DatePosted = styled.div`
  font-size: 1rem;
  color: #888;
  text-align: left;
  margin-top: 0.5rem;
`;

const RelatedImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
`;

const RelatedImageWrapper = styled.div`
  width: 220px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  padding: 1.2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
`;

const RelatedImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ImageTitle = styled.h4`
  font-size: 1.2rem;
  color: #333;
  margin: 0.8rem 0;
`;

const ImageDescription = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 0.5rem 0;
`;

const HabariDetail = () => {
  const { id } = useParams(); // Get the Habari ID from the URL
  const [habari, setHabari] = useState(null);

  useEffect(() => {
    // Fetch Habari data from the API
    axios
      .get(`https://polisi.go.tz:8000/habari/${id}/`)
      .then((response) => {
        setHabari(response.data);
      })
      .catch((error) => {
        console.error('Error fetching Habari details:', error);
      });
  }, [id]);

  if (!habari) {
    return (
      <DetailWrapper>
        <p>Loading...</p>
      </DetailWrapper>
    ); // Loading state while fetching data
  }

  // Extract related images if they exist
  const relatedImages = habari.related_images || [];

  return (
    <DetailWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title>{habari.title}</Title>
          <Description>{habari.description}</Description>
          <DateWrapper>
            <DatePosted>Date Posted: {new Date(habari.date_posted).toLocaleDateString()}</DatePosted>
          </DateWrapper>
        </TitleWrapper>

        {habari.cover_image && (
          <CoverImage
            src={habari.cover_image}
            alt={habari.title}
          />
        )}
      </ContentWrapper>

      {/* Display Related Images if any */}
      {relatedImages.length > 0 && (
        <RelatedImagesWrapper>
          {relatedImages.map((image) => (
            <RelatedImageWrapper key={image.id}>
              <RelatedImage
                src={image.image}
                alt={image.title}
              />
              <ImageTitle>{image.title}</ImageTitle>
              <ImageDescription>{image.description}</ImageDescription>
            </RelatedImageWrapper>
          ))}
        </RelatedImagesWrapper>
      )}
      {relatedImages.length === 0 && <p>No related images available.</p>}
    </DetailWrapper>
  );
};

export default HabariDetail;

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ProfileImage from '../assets/cpf/cpf.png'; // Profile image

const TopViewWrapper = styled.div`
  width: 100%; /* Full width */
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #e9f1f9;
  margin: auto;
  flex-wrap: wrap;

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 0;
  }
`;

const LeftContainer = styled.div`
  flex: 1 1 15%;
  margin: 0.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    margin: 0.5rem 0;
    order: 2;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 10px solid #004080;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Name = styled.h3`
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Word = styled.h3`
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const RightContainer = styled.div`
  flex: 1 1 70%;
  margin: 0.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    margin: 0.5rem 0;
    order: 1;
  }
`;

const Slide = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.$active ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  background-size: cover;
  background-position: center;
  background-image: ${(props) => `url(${props.src})`};
`;

const OverlayText = styled.div`
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 64, 128, 0.5);
  color: #fff;
  border: none;
  padding: 0.4rem 0.6rem;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: rgba(0, 64, 128, 0.7);
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
  }
`;

const PrevButton = styled(NavButton)`
  left: 20px;
`;

const NextButton = styled(NavButton)`
  right: 20px;
`;

const TopView = () => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [expandedStates, setExpandedStates] = useState([]);

  useEffect(() => {
  
    fetchSlides();
  }, []);

  const fetchSlides = async () => {
    try {
      const response = await fetch('https://polisi.go.tz:8000/api/slides/');
      const data = await response.json();
      const activeSlides = data.filter((slide) => slide.status === 'active');
      setSlides(activeSlides);
      setExpandedStates(activeSlides.map(() => false));
    } catch (error) {
      console.error('Error fetching slides:', error);
    }
  };


  const handleToggleExpand = (index) => {
    setExpandedStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };
  

  const handleNextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  }, [slides.length]);

  const handlePrevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  }, [slides.length]);

  useEffect(() => {
    const slideInterval = setInterval(handleNextSlide, 6000);
    return () => clearInterval(slideInterval);
  }, [handleNextSlide]);

  return (
    <TopViewWrapper>
      <LeftContainer>
        <Image src={ProfileImage} alt="Profile" />
        <Name>IGP Camillus M. Wambura</Name>
        <Word>CPF</Word>
      </LeftContainer>
      <RightContainer>
        {slides.map((slide, index) => (
          <Slide
            key={index}
            src={slide.display_image.replace('/api/slides/', '/media/')}
            $active={index === currentSlide}
          >
            <OverlayText>
              <p>

              {/* {slide.description} */}
                {expandedStates[index]
                  ? slide.description
                  : `${slide.description.split(' ').slice(0, 5).join(' ')}...`}
              </p>
              <button
                onClick={() => handleToggleExpand(currentSlide)}
                style={{
                  color: '#007bff',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {expandedStates[index] ? 'Read Less' : 'Read More'}
              </button>
            </OverlayText>
          </Slide>
        ))}
        <PrevButton onClick={handlePrevSlide}>&#10094;</PrevButton>
        <NextButton onClick={handleNextSlide}>&#10095;</NextButton>
      </RightContainer>
    </TopViewWrapper>
  );
};

export default TopView;

import React from 'react';
import ReactDOM from 'react-dom/client'; // React 18 rendering API
import './index.css';
import App from './App';
import './i18n'; // Import i18n configuration
import reportWebVitals from './reportWebVitals';

// Create the root using React 18's API
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Measure performance (optional)
reportWebVitals();

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Wrapper for the whole Service component
const ServiceWrapper = styled.div`
  height: auto;
  width: 100%;
  background-image: 
    repeating-linear-gradient(45deg, 
        rgba(0, 64, 128, 0.1) 0, 
        rgba(0, 64, 128, 0.1) 1px, 
        transparent 1px, 
        transparent 10px),
    repeating-linear-gradient(135deg, 
        rgba(0, 64, 128, 0.1) 0, 
        rgba(0, 64, 128, 0.1) 1px, 
        transparent 1px, 
        transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

// Title styling
const Title = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #004080;
  margin-bottom: 1.5rem;
  letter-spacing: 1.5px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`;

// Container for circles
const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
  white-space: nowrap;
  justify-content: center;
  padding: 1rem;
  position: relative; /* Ensure container is a positioning context */

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0.5rem;
    justify-content: space-around;
  }
`;

// Tooltip styling for hover effect
const Tooltip = styled.span`
  visibility: hidden;
  background-color: #004080;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1000; /* Increased z-index to ensure it appears above other elements */
  bottom: 140%; /* Adjusted to ensure it appears above the circle */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  font-size: 0.9rem;
  font-weight: 400;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  width: 220px;
  pointer-events: none; /* Prevent tooltip from interfering with hover events */

  /* Arrow for the tooltip */
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #004080 transparent transparent transparent;
  }

  @media (max-width: 768px) {
    width: 180px;
    font-size: 0.8rem;
    bottom: 120%;
  }
`;

// Individual circular item styling with tooltip
const CircleItem = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: linear-gradient(145deg, #0066cc, #004080);
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-7px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
  }

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    font-size: 1rem;
    margin: 0.5rem;
  }
`;

const Service = () => {
  const { t } = useTranslation();

  return (
    <ServiceWrapper>
      <Title>{t('servicesTitle')}</Title>
      <Container>
        <CircleItem
          href="https://taarifa.tpf.go.tz"
          target="_blank"
          rel="noopener noreferrer"
          title="Crime Early Warning And Early Response System" // Fallback tooltip
        >
          CEWERS
          <Tooltip>Crime Early Warning And Early Response System</Tooltip>
        </CircleItem>
        <CircleItem
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          title="FireArms Licence Application Portal"
        >
          FAMIS
          <Tooltip>FireArms Licence Application Portal</Tooltip>
        </CircleItem>
        <CircleItem
          href="https://lormis.tpf.go.tz"
          target="_blank"
          rel="noopener noreferrer"
          title="Loss Report"
        >
          LORMIS
          <Tooltip>Loss Report</Tooltip>
        </CircleItem>
        <CircleItem
          href="https://pcc.tpf.go.tz"
          target="_blank"
          rel="noopener noreferrer"
          title="Police Clearance Certificate"
        >
          PCC
          <Tooltip>Police Clearance Certificate</Tooltip>
        </CircleItem>
        <CircleItem
          href="https://pscgp.tpf.go.tz"
          target="_blank"
          rel="noopener noreferrer"
          title="Private Security Companies Governance Portal"
        >
          PSCGP
          <Tooltip>Private Security Companies Governance Portal</Tooltip>
        </CircleItem>
        <CircleItem
          href="https://tms.tpf.go.tz"
          target="_blank"
          rel="noopener noreferrer"
          title="Road Traffic Offence Check"
        >
          RTO Check
          <Tooltip>Road Traffic Offence Check</Tooltip>
        </CircleItem>
      </Container>
    </ServiceWrapper>
  );
};

export default Service;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'; // Importing useTranslation

import DPA from '../assets/vyuo/dpa.png'; 
import TPS from '../assets/vyuo/tps.png'; 
import ZNZ from '../assets/vyuo/znz.png'; 
import KIDATU from '../assets/vyuo/kidatu.png'; 
import MARINE from '../assets/vyuo/marine.png'; 

// Wrapper for the Vyuo component
const VyuoWrapper = styled.div`
  width: 100%; /* Full width */
  position: relative;
  background-color: #e9f1f9;
  display: flex;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
  margin: auto;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

// Container for centering content with dynamic width
const ContentContainer = styled.div`
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Title styling
const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem; /* Reduced font size */
  font-weight: bold;
  color: #004080;
  margin-bottom: 1rem;
  letter-spacing: 1.5px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 1024px) {
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// Button to toggle the visibility of the items (for small screens)
const ToggleButton = styled.button`
  background-color: #004080;
  color: white;
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  margin-bottom: 1rem;
  display: none;

  @media (max-width: 768px) {
    display: block; /* Show button on small screens */
  }

  &:hover {
    background-color: #0066cc;
  }
`;

// Row for the items
const ItemRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Ensures center alignment */
  gap: 1.5rem;
  width: 100%;
  margin-top: 1rem;

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')}; /* Toggle visibility */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Align items in the center */
    align-items: center; /* Align items in the center */
    padding: 0;
  }
`;

// Individual item with image and label styling
const RectangleItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px; /* Increased width */
  height: 150px; /* Increased height */
  background: linear-gradient(145deg, #0066cc, #004080);
  text-decoration: none;
  color: white;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 0.8rem;
  flex-shrink: 0; /* Prevent shrinking on small screens */
  box-sizing: border-box; /* Ensures padding is included in width/height calculations */
  overflow: hidden; /* Prevents overflow */
  text-align: center; /* Ensures text is centered */

  &:hover {
    transform: translateY(-7px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 1024px) {
    width: 200px;
    height: 150px;
  }

  @media (max-width: 768px) {
    width: 200px; /* Fixed width */
    height: 150px; /* Fixed height */
  }

  @media (max-width: 480px) {
    width: 200px; /* Fixed width */
    height: 150px; /* Fixed height */
  }
`;

// Image styling
const ItemImage = styled.img`
  width: 100%;
  height: 90px; /* Adjusted image height */
  border-radius: 8px;
  margin-bottom: 0.5rem;
`;

// Label styling with further reduced font size and added wrapping
const ItemLabel = styled.span`
  font-size: 0.7rem; /* Adjusted font size */
  font-weight: 500;
  color: white;
  white-space: normal; /* Allow text to wrap within the container */
  word-wrap: break-word; /* Ensures long text wraps to the next line */
  line-height: 1.2rem; /* Adjusted line height for better spacing */
  max-width: 100%; /* Ensures text does not overflow */
  padding: 0 0.3rem; /* Padding to ensure text doesn't touch the edges */
`;

const Vyuo = () => {
  const [isOpen, setIsOpen] = useState(false); // State to toggle the visibility of items
  const { t } = useTranslation(); // Use translation hook to access the 't' function

  return (
    <VyuoWrapper>
      <ContentContainer>
        <Title>{t('institutionTitle')}</Title> {/* Use translation for the title */}
        <ToggleButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? 'Hide Institutions' : 'Show Institutions'}
        </ToggleButton>
        <ItemRow isOpen={isOpen}>
          <RectangleItem href="#vyuo1">
            <ItemImage src={DPA} alt="DPA" />
            <ItemLabel>Dar es salaam Police Academy</ItemLabel>
          </RectangleItem>
          <RectangleItem href="#vyuo2">
            <ItemImage src={TPS} alt="TPS" />
            <ItemLabel>Tanzania Police School Moshi</ItemLabel>
          </RectangleItem>
          <RectangleItem href="#vyuo3">
            <ItemImage src={ZNZ} alt="ZnZ" />
            <ItemLabel>Zanzibar Police College</ItemLabel>
          </RectangleItem>
          <RectangleItem href="#vyuo4">
            <ItemImage src={KIDATU} alt="Kidatu" />
            <ItemLabel>Tanzania Police Staff College</ItemLabel>
          </RectangleItem>
          <RectangleItem href="#vyuo5">
            <ItemImage src={MARINE} alt="Marine" />
            <ItemLabel>Marine Police College</ItemLabel>
          </RectangleItem>
        </ItemRow>
      </ContentContainer>
    </VyuoWrapper>
  );
};

export default Vyuo;

import React, { useEffect, useState } from 'react';

function HistoriaYaJeshi() {
  const [managements, setManagements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch management data from the backend
    fetch('https://polisi.go.tz:8000/managements/')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setManagements(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setManagements([]); // Ensure `managements` is set to an empty array on error
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div style={styles.loading}>Loading...</div>;
  }

  return (
    <section style={styles.section}>
      {managements.length > 0 ? (
        <article style={styles.article}>
          {managements.map((management) => (
            <div key={management.id} style={styles.managementItem}>
              <h2 style={styles.subHeader}>Historia ya Jeshi</h2>
              <div style={styles.contentWrapper}>
                {management.image && (
                  <div style={styles.managementImageWrapper}>
                    <img
                      src={`https://polisi.go.tz:8000${management.image}`}
                      alt={management.title}
                      style={styles.managementImage}
                    />
                  </div>
                )}
                <div style={styles.managementDescriptionWrapper}>
                  <p style={styles.managementDescription}>{management.description}</p>
                </div>
              </div>
            </div>
          ))}
        </article>
      ) : (
        <p style={styles.noData}>Hakuna data ya kuonyesha kwa sasa.</p>
      )}
    </section>
  );
}

// Inline styles for better organization and readability
const styles = {
  section: {
    position: 'relative',
    height: 'auto',
    maxWidth: '1600px',
    backgroundImage:
      `repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
      repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px)`,
    backgroundColor: '#e9f1f9',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1rem',
    boxShadow: '0 -8px 10px rgba(0, 0, 0, 0.2)',
  },
  subHeader: {
    fontSize: '2.2rem',  // Adjusted font size for emphasis
    fontWeight: 'bold',
    color: '#004080',    // Title color set to #004080
    textTransform: 'uppercase',
    marginBottom: '1rem',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    textAlign: 'center', // Center the title
    position: 'relative',
  },
  subHeaderLine: {
    position: 'absolute',
    bottom: '-5px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '50%',
    height: '2px',
    backgroundColor: '#004080',  // Matching color of the title
  },
  article: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    alignItems: 'center',
  },
  managementItem: {
    backgroundColor: '#fff',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '1440px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1rem',
    alignItems: 'center',
  },
  managementDescriptionWrapper: {
    flex: 1,
    textAlign: 'center',
    padding: '0 1rem',
  },
  managementDescription: {
    fontSize: '1.1rem',
    margin: 0,
    fontFamily: '"Georgia", serif',
    color: '#555',
  },
  managementImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    marginTop: '1rem',
  },
  managementImage: {
    width: '1200px',
    maxWidth: '100%',
    height: '250px',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  noData: {
    color: '#555',
    fontSize: '1.2rem',
    marginTop: '2rem',
  },
  loading: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#555',
  },
  '@media (min-width: 769px)': {
    contentWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: '2rem',
      alignItems: 'center',
    },
    managementDescriptionWrapper: {
      textAlign: 'left',
    },
    managementImageWrapper: {
      width: '40%',
    },
    managementDescription: {
      fontSize: '1.2rem',
    },
  },
};

export default HistoriaYaJeshi;

import React, { useEffect, useState } from 'react';

const MalengoNaMaono = () => {
  const [dhamiraData, setDhamiraData] = useState(null);
  const [diraData, setDiraData] = useState(null);
  const [dhimaData, setDhimaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const diraResponse = await fetch('https://polisi.go.tz:8000/api/dira');
        const dhimaResponse = await fetch('https://polisi.go.tz:8000/api/dhima');
        const dhamiraResponse = await fetch('https://polisi.go.tz:8000/dhamira');

        if (!dhamiraResponse.ok || !diraResponse.ok || !dhimaResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const dhamiraData = await dhamiraResponse.json();
        const diraData = await diraResponse.json();
        const dhimaData = await dhimaResponse.json();

        setDhamiraData(dhamiraData[0]); // Assuming API returns an array
        setDiraData(diraData[0]);
        setDhimaData(dhimaData[0]);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div style={styles.loading}>Loading...</div>;
  }

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {diraData && dhimaData && dhamiraData ? (
          <>
            {/* Dira Section */}
            <div>
              <h2 style={styles.subHeading}>Dira</h2>
              <ul style={styles.list}>
                {diraData.dira_description && <li style={styles.listItem}>{diraData.dira_description}</li>}
              </ul>
            </div>

            {/* Dhima Section */}
            <div>
              <h2 style={styles.subHeading}>Dhima</h2>
              <ul style={styles.list}>
                {dhimaData.dhima_description && <li style={styles.listItem}>{dhimaData.dhima_description}</li>}
              </ul>
            </div>

            {/* Dhamira Section */}
            <div>
              <h2 style={styles.subHeading}>Misingi Mikuu</h2>
              <ul style={styles.list}>
                {dhamiraData.dhamira_title_1 && <li style={styles.titleItem}>{dhamiraData.dhamira_title_1}</li>}
                {dhamiraData.dhamira1_description && <li style={styles.listItem}>{dhamiraData.dhamira1_description}</li>}
                {dhamiraData.dhamira_title_2 && <li style={styles.titleItem}>{dhamiraData.dhamira_title_2}</li>}
                {dhamiraData.dhamira2_description && <li style={styles.listItem}>{dhamiraData.dhamira2_description}</li>}
                {dhamiraData.dhamira_title_3 && <li style={styles.titleItem}>{dhamiraData.dhamira_title_3}</li>}
                {dhamiraData.dhamira3_description && <li style={styles.listItem}>{dhamiraData.dhamira3_description}</li>}
                {dhamiraData.dhamira_title_4 && <li style={styles.titleItem}>{dhamiraData.dhamira_title_4}</li>}
                {dhamiraData.dhamira4_description && <li style={styles.listItem}>{dhamiraData.dhamira4_description}</li>}
                {dhamiraData.dhamira_title_5 && <li style={styles.titleItem}>{dhamiraData.dhamira_title_5}</li>}
                {dhamiraData.dhamira5_description && <li style={styles.listItem}>{dhamiraData.dhamira5_description}</li>}
              </ul>
            </div>
          </>
        ) : (
          <p style={styles.noData}>Hakuna data ya kuonyesha kwa sasa.</p>
        )}
      </div>
    </section>
  );
};

// Styles
const styles = {
  section: {
    position: 'relative',
    height: 'auto',
    maxWidth: '1600px',
    backgroundImage:
      `repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
      repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px)`,
    backgroundColor: '#e9f1f9',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    boxShadow: '0 -8px 10px rgba(0, 0, 0, 0.2)',
  },
  container: {
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '1440px',
  },
  subHeading: {
    fontSize: '2rem',
    marginBottom: '1rem',
    color: '#004080',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: '"Georgia", serif',
    borderBottom: '3px solid #004080',
    paddingBottom: '0.5rem',
    
  },
  list: {
    paddingLeft: '1.5rem',
    listStyleType: 'none', // Removes dots for all list items
    fontSize: '1.2rem',
    color: '#333',
    fontFamily: '"Georgia", serif',
  },
  listItem: {
    marginBottom: '0.5rem',
    lineHeight: '1.6',
    fontFamily: '"Georgia", serif',
  },
  titleItem: {
    fontSize: '1.5rem',  // Larger font for title
    fontWeight: 'bold',  // Make title bold
    color: '#004080',  // Dark blue color for contrast
    marginTop: '1rem',
    marginBottom: '0.5rem',
    fontFamily: '"Georgia", serif',
    listStyleType: 'none', // Remove dot style
  },
  loading: {
    textAlign: 'center',
    color: '#555',
    fontSize: '1.2rem',
    fontFamily: '"Georgia", serif',
  },
  error: {
    textAlign: 'center',
    color: '#d9534f',
    fontSize: '1.2rem',
    fontFamily: '"Georgia", serif',
  },
  noData: {
    textAlign: 'center',
    color: '#d9534f',
    fontSize: '1.2rem',
    fontFamily: '"Georgia", serif',
  },
};

export default MalengoNaMaono;

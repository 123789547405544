import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import TopBar from './components/TopBar';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';
import TopView from './components/TopView';
import Dharura from './components/Dharura';
import Kamisheni from './components/Kamisheni';
import Taarifa from './components/Taarifa';
import Service from './components/Service';
import Vyuo from './components/Vyuo';
import HistoriaYaJeshi from './components/HistoriaYaJeshi';
import KamisheniDetail from './components/KamisheniDetail';
import Mawasiliano from './components/Mawasiliano';
import Habari from './components/Habari';
import HabariDetail from './components/HabariDetail'; // Import HabariDetail component
import Matukio from './components/Matukio'; // Import Matukio component
import MatukioDetail from './components/MatukioDetail'; // Import MatukioDetail component
import MalengoNaMaono from './components/MalengoNaMaono';
import MuundoWaJeshi from './components/MuundoWaJeshi';
import VyeoVyaPolisi from './components/VyeoVyaPolisi';
import WakuuWaPolisiWaliopita from './components/WakuuWaPolisiWaliopita';
import Ajira from './components/Ajira';
import Jarida from './components/Jarida';

// Layout component to render common elements like TopBar, Navbar, Header, and Footer
function Layout({ children }) {
  const location = useLocation();

  // Check for specific routes
  const isSpecialRoute = [
    '/managements', '/kamisheni/', '/mawasiliano', '/habari', 
    '/dhamira', '/muundo', '/vyeo', '/wakuu', '/ajira', '/jarida', '/matukio'
  ].some(route => location.pathname.startsWith(route));

  return (
    <>
      {/* Always render common elements like TopBar, Header, Navbar */}
      <TopBar />
      <Header />
      <Navbar />
      
      {/* Render children (specific page content) */}
      {children}

      {/* Conditionally render other components if not on special routes */}
      {!isSpecialRoute && (
        <>
          <TopView />
          <Dharura />
          <Kamisheni />
          <Taarifa />
          <Service />
          <Vyuo />
        </>
      )}
      
      {/* Always render Footer */}
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/managements" element={<HistoriaYaJeshi />} />
          <Route path="/kamisheni/:id" element={<KamisheniDetail />} />
          <Route path="/mawasiliano" element={<Mawasiliano />} />
          <Route path="/habari" element={<Habari />} />
          <Route path="/habari/:id" element={<HabariDetail />} /> {/* Added HabariDetail route */}
          <Route path="/matukio" element={<Matukio />} /> {/* Added Matukio route */}
          <Route path="/matukio/:id" element={<MatukioDetail />} /> {/* Added MatukioDetail route */}
          <Route path="/dhamira" element={<MalengoNaMaono />} />
          <Route path="/muundo" element={<MuundoWaJeshi />} />
          <Route path="/vyeo" element={<VyeoVyaPolisi />} />
          <Route path="/wakuu" element={<WakuuWaPolisiWaliopita />} />
          <Route path="/ajira" element={<Ajira />} />
          <Route path="/jarida" element={<Jarida />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// Styled Components
const TaarifaWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #e9f1f9;
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin: auto;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const TaarifaContainer = styled.div`
  width: 100%;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Container = styled.div`
  flex: 1;
  margin: 0 0.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-bottom: 2px solid #e0e0e0;

  @media (max-width: 768px) {
    flex-basis: 100%;
    padding: 0;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  color: #004080;
  margin-bottom: 1rem;
`;

const NewsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
`;

const NewsRow = styled.tr`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  flex-wrap: wrap;
  border-bottom: 1px solid #f0f0f0;
`;

const NewsImageCell = styled.td`
  width: 100%;
  max-width: 150px;
  padding-right: 1rem;

  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 0;
  }
`;

const NewsImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
`;

const NewsContentCell = styled.td`
  flex: 1;
  text-align: left;
  padding-left: 1rem;
`;

const NewsTitle = styled.h3`
  font-size: 1.1rem;
  color: #004080;
  margin: 0;
  font-weight: 600;
  text-align: justify;
`;

const NewsDate = styled.p`
  font-size: 0.875rem;
  color: #888;
  margin: 0.25rem 0;
  text-align: justify;
`;

const NewsTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const NewsText = styled.p`
  margin: 0;
  flex: 1;
  text-align: justify;
`;

const ViewAllLink = styled(Link)`
  color: #004080;
  text-decoration: none;
  font-weight: bold;
  margin-left: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const AllNewsLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const AllNewsLink = styled(Link)`
  color: #004080;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Taarifa = () => {
  const { t } = useTranslation(); // Importing translations
  const [newsItems, setNewsItems] = useState([]);
  const [matukioItems, setMatukioItems] = useState([]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(new Date(dateString));
  };

  const truncateText = (text, wordLimit) => {
    if (!text) return ""; // If text is undefined or null, return an empty string
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  useEffect(() => {
    fetch("https://polisi.go.tz:8000/api/habari/latest/")
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter((item) => item.status === "active");
        const formattedData = filteredData.map((item) => ({
          ...item,
          imgSrc: `https://polisi.go.tz:8000${item.cover_image}`,
        }));
        setNewsItems(formattedData);
      })
      .catch((error) => console.error("Error fetching news data:", error));
  }, []);

  useEffect(() => {
    fetch("https://polisi.go.tz:8000/api/matukio/latest/")
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter((item) => item.status === "active");
        const formattedData = filteredData.map((item) => ({
          ...item,
          imgSrc: `https://polisi.go.tz:8000${item.cover_image}`,
        }));
        setMatukioItems(formattedData);
      })
      .catch((error) => console.error("Error fetching matukio data:", error));
  }, []);

  return (
    <TaarifaWrapper>
      <TaarifaContainer>
        {/* Left Container for News */}
        <Container>
          <Title>{t("latestNews")}</Title>
          <NewsTable>
            <tbody>
              {newsItems.map((news, index) => (
                <NewsRow key={index}>
                  <NewsImageCell>
                    <NewsImage src={news.imgSrc} alt={news.title} />
                  </NewsImageCell>
                  <NewsContentCell>
                    <NewsTitle>{news.title}</NewsTitle>
                    <NewsDate>{formatDate(news.date_posted)}</NewsDate>
                    <NewsTextContainer>
                      <NewsText>{truncateText(news.content, 30)}</NewsText>
                      <ViewAllLink to={`/habari/${news.id}`}>
                        {t("readMore")}
                      </ViewAllLink>
                    </NewsTextContainer>
                  </NewsContentCell>
                </NewsRow>
              ))}
            </tbody>
          </NewsTable>
          <AllNewsLinkContainer>
            <AllNewsLink to="/habari">{t("viewAllNews")}</AllNewsLink>
          </AllNewsLinkContainer>
        </Container>

        {/* Right Container for Events */}
        <Container>
          <Title>{t("events")}</Title>
          <NewsTable>
            <tbody>
              {matukioItems.map((event, index) => (
                <NewsRow key={index}>
                  <NewsImageCell>
                    <NewsImage src={event.imgSrc} alt={event.title} />
                  </NewsImageCell>
                  <NewsContentCell>
                    <NewsTitle>{event.title}</NewsTitle>
                    <NewsDate>{formatDate(event.date_posted)}</NewsDate>
                    <NewsTextContainer>
                      <NewsText>{truncateText(event.content, 30)}</NewsText>
                      <ViewAllLink to={`/matukio/${event.id}`}>
                        {t("readMore")}
                      </ViewAllLink>
                    </NewsTextContainer>
                  </NewsContentCell>
                </NewsRow>
              ))}
            </tbody>
          </NewsTable>
          <AllNewsLinkContainer>
            <AllNewsLink to="/matukio">{t("viewAllEvents")}</AllNewsLink>
          </AllNewsLinkContainer>
        </Container>
      </TaarifaContainer>
    </TaarifaWrapper>
  );
};

export default Taarifa;

import React, { useEffect, useState } from 'react';

function MuundoDisplay() {
  const [muundoList, setMuundoList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the backend API
    fetch('https://polisi.go.tz:8000/muundo/') // Update the endpoint URL if necessary
      .then((response) => response.json())
      .then((data) => {
        setMuundoList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching Muundo data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div style={styles.loading}>Loading...</div>;
  }

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {muundoList.length > 0 ? (
          <div style={styles.muundoContainer}>
            {muundoList.map((muundo) => (
              <div key={muundo.id} style={styles.muundoCard}>
                <h2 style={styles.title}>{muundo.title}</h2>
                {/* <p style={styles.description}>{muundo.description}</p> */}
                {muundo.image && (
                  <img
                    src={`https://polisi.go.tz:8000${muundo.image}`} // Ensure the backend serves media files correctly
                    alt={muundo.title}
                    style={styles.image}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <p style={styles.noData}>Hakuna data ya kuonyesha kwa sasa.</p>
        )}
      </div>
    </section>
  );
}

// Inline styles for the component
const styles = {
  section: {
    position: 'relative',
    height: 'auto',
    maxWidth: '1600px',
    backgroundImage:
      `repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
      repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px)`,
    backgroundColor: '#e9f1f9',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    boxShadow: '0 -8px 10px rgba(0, 0, 0, 0.2)',
  },
  container: {
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '1440px',
  },
  muundoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1.5rem',
  },
  muundoCard: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  title: {
    fontSize: '2.2rem',
    color: '#003d80',
    marginBottom: '0.75rem',
    textAlign: 'center',
  },
  description: {
    fontSize: '1rem',
    textAlign: 'justify',
    marginBottom: '1rem',
    color: '#555',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  noData: {
    textAlign: 'center',
    color: '#555',
    fontSize: '1rem',
    marginTop: '1.5rem',
  },
  footer: {
    marginTop: '2rem',
    textAlign: 'center',
    padding: '1rem 0',
    borderTop: '1px solid #ddd',
  },
  footerText: {
    fontSize: '0.9rem',
    color: '#555',
  },
  loading: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#555',
  },
};

export default MuundoDisplay;

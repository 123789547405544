import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

// Styled Components
const Topbar = styled.section`
  position: fixed; /* Keep the TopBar fixed at the top */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  z-index: 1000; /* Ensure it stays on top of other content */
  background: linear-gradient(to right, #00c6ff, #0072ff);
  color: #fff;
  padding: 0.2rem 1rem; /* Minimal padding for a sleeker appearance */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px; /* Reduced height for a more compact design */

  /* Remove the media query that hides the TopBar on small screens */
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 0.8rem;
  padding-top: 4px;

  .social-link {
    color: #fff;
    font-size: 1.2rem;
    transition: color 0.3s;

    &:hover {
      color: #ffdd57;
    }
  }

  @media (max-width: 768px) {
    display: flex; /* Keep the social links visible on small devices */
  }
`;

const LanguageSwitch = styled.div`
  display: flex;
  gap: 0.8rem;
  padding-top: 0.5px;
  justify-content: flex-end; /* Align to the right */
  margin-right: 0.8%;

  span {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s;

    &:hover {
      color: #ffdd57; /* Change to yellow on hover */
    }
  }

  span:last-of-type {
    padding-right: 1.5rem; /* Add extra space at the end of the Swahili text */
  }

  @media (max-width: 768px) {
    width: 100%; /* Ensure full width for alignment */
    justify-content: flex-end; /* Align text to the right */
    margin-top: 0.5rem; /* Add spacing between sections */
  }
`;

const Spacer = styled.div`
  height: 30px; /* Match the height of the TopBar */
`;

// TopBar Component
function TopBar() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Topbar>
        <div className="p-3">
          <SocialLinks>
            <a
              href="https://facebook.com/Polisi-Tanzania"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FaFacebookF />
            </a>

            <a
              href="https://twitter.com/tanpol"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FaTwitter />
            </a>

            <a
              href="https://instagram.com/polisi.tanzania"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FaInstagram />
            </a>

            <a
              href="https://youtube.com/@usalamatv"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FaYoutube />
            </a>
          </SocialLinks>
        </div>
        <LanguageSwitch>
          <span onClick={() => changeLanguage('en')}>English</span>
          <span style={{ margin: '0 0px' }}>|</span>
          <span onClick={() => changeLanguage('sw')}>Swahili</span>
        </LanguageSwitch>
      </Topbar>

      <Spacer /> {/* Add spacing to prevent content overlap */}
    </>
  );
}

export default TopBar;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Language translations
const resources = {
  en: {
    translation: {
      smallText: "THE UNITED REPUBLIC OF TANZANIA",
      mediumText: "MINISTRY OF HOME AFFEARS",
      largeText: "TANZANIA POLICE FORCE",
      navbar: {
        home: "Home",
        about: "About",
        police_force: "Police Force",
        goals_visions: "Goals & Visions",
        structure: "Structure",
        ranks: "Ranks",
        past_leaders: "Past Leaders",
        news: "News",
        commissions: "Commissions",
        publications: "Publications",
        careers: "Careers",
        contacts: "Contacts",
      },
      emergencyNumbers: {
        title: "Emergency Numbers",
        numbers: [
          { label: "Crime Prevention", number: "111" },
          { label: "Police", number: "112" },
          { label: "Pccb", number: "113" },
          { label: "Firefighters", number: "114" },
          { label: "Ambulance", number: "115" },
          { label: "Child Support", number: "116" },
        ],
      },
      latestNews: "Latest News",
      readMore: "Read More...",
      viewAllNews: "View All News",
      events: "Events",
      viewAllEvents: "View All Events",
      kamisheniTitle: "Commissions of the Tanzania Police Force",
      servicesTitle: "Services of the Tanzania Police Force",
      institutionTitle: "Tanzania Police Force Colleges",
      usefulLinks: "Useful Links",
      ikulu: "The State House Tanzania",
      governmentSite: "The Government Site",
      immigration: "Immigration Tanzania",
      homeAffairs: "Ministry of Home Affairs",
      contact: {
        title: "Contact Us",
        address: "Police Headquarters",
        addressLine2: "P. O. Box 961, Dodoma, Tanzania",
        phone1: "+255787668306",
        phone2: "0262323585",
        phone3: "0262323586",
        email: "info.phq@tpf.go.tz",
      },
    },
  },
  sw: {
    translation: {
      smallText: "JAMHURI YA MUUNGANO WA TANZANIA",
      mediumText: "WIZARA YA MAMBO YA NDANI YA NCHI",
      largeText: "JESHI LA POLISI TANZANIA",
      navbar: {
        home: "Mwanzo",
        about: "Kuhusu",
        police_force: "Jeshi la Polisi",
        goals_visions: "Malengo & Maono",
        structure: "Muundo wa Jeshi",
        ranks: "Vyeo vya Polisi",
        past_leaders: "Wakuu wa Polisi Waliopita",
        news: "Habari",
        commissions: "Kamisheni",
        publications: "Machapisho",
        careers: "Ajira",
        contacts: "Mawasiliano",
      },
      emergencyNumbers: {
        title: "Namba za Dharura Tanzania",
        numbers: [
          { label: "Kuzuia uhalifu", number: "111" },
          { label: "Polisi", number: "112" },
          { label: "Takukuru", number: "113" },
          { label: "Zimamoto", number: "114" },
          { label: "Gari ya wagonjwa", number: "115" },
          { label: "Msaada kwa mtoto", number: "116" },
        ],
      },
      latestNews: "Habari Mpya",
      readMore: "Soma Zaidi...",
      viewAllNews: "Tazama Habari Zote",
      events: "Matukio",
      viewAllEvents: "Tazama Matukio Yote",
      kamisheniTitle: "Kamisheni za Jeshi la Polisi",
      servicesTitle: "Huduma za Jeshi la Polisi",
      institutionTitle: "Vyuo vya Jeshi la Polisi Tanzania",
      usefulLinks: "Tovuti Mashuhuri",
      ikulu: "Ikulu Tanzania",
      governmentSite: "Tovuti Kuu ya Serikali",
      immigration: "Uhamiaji Tanzania",
      homeAffairs: "Wizara ya Mambo ya Ndani",
      contact: {
        title: "Wasiliana nasi",
        address: "Makao Makuu ya Polisi",
        addressLine2: "S. L. P 961, Dodoma, Tanzania",
        phone1: "+255787668306",
        phone2: "0262323585",
        phone3: "0262323586",
        email: "info.phq@tpf.go.tz",
      },
    },
  },
};

// Initialize i18next
i18n.use(initReactI18next).init({
  resources,
  lng: "sw", // Default language set to Swahili
  fallbackLng: "en", // Fallback language is English
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

export default i18n;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Footer Wrapper
const FooterWrapper = styled.div`
  background: #6c96aa;
  padding: 0 0 20px 0;
  color: #fff;
  font-size: 12px;
  width: 100%;
`;

// Footer Top Section
const FooterTop = styled.div`
  position: relative;
  width: 100%;
  background: #004080;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

// Footer Container
const FooterContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  @media (max-width: 768px) {
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
  }
`;

// Individual Container Styling
const Container = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    min-width: 250px;
  }
`;

// Inner Content Wrapper
const ContentWrapper = styled.div`
  text-align: left;
  width: 100%;
  max-width: 250px;
`;

// Footer Info Title
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 8px;
  padding: 2px 0;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 2px;
  color: #6c96aa;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

// Footer Paragraph
const Paragraph = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

// List for Links
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

// List Item Styling
const ListItem = styled.li`
  margin-bottom: 5px;

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #c0e0f0;
    }
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <FooterTop>
        <FooterContainer>
          {/* Emergency Contact */}
          <Container>
            <ContentWrapper>
              <Title>{t('emergencyNumbers.title')}</Title>
              {t('emergencyNumbers.numbers', { returnObjects: true }).map((item, index) => (
                <Paragraph key={index}>{item.label}: {item.number}</Paragraph>
              ))}
            </ContentWrapper>
          </Container>

          {/* Useful Links */}
          <Container>
            <ContentWrapper>
              <Title>{t('usefulLinks')}</Title>
              <List>
                <ListItem>
                  <a
                    href="https://www.ikulu.go.tz"
                    target="_blank" // Opens in new tab
                    rel="noopener noreferrer" // Security best practice
                  >
                    {t('ikulu')}
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    href="https://www.tanzania.go.tz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('governmentSite')}
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    href="https://www.immigration.go.tz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('immigration')}
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    href="https://www.moha.go.tz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('homeAffairs')}
                  </a>
                </ListItem>
              </List>
            </ContentWrapper>
          </Container>

          {/* Contact Information */}
          <Container>
            <ContentWrapper>
              <Title>{t('contact.title')}</Title>
              <Paragraph>{t('contact.address')}</Paragraph>
              <Paragraph>{t('contact.addressLine2')}</Paragraph>
              <Paragraph>{t('contact.phone1')}</Paragraph>
              <Paragraph>{t('contact.phone2')}</Paragraph>
              <Paragraph>{t('contact.phone3')}</Paragraph>
              <Paragraph>{t('contact.email')}</Paragraph>
            </ContentWrapper>
          </Container>
        </FooterContainer>
      </FooterTop>
    </FooterWrapper>
  );
};

export default Footer;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Styled Components for Ajira page layout
const Section = styled.section`
  position: relative;
  height: auto;
 width: 100%; /* Full width */
  background-image: 
    repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

const AjiraWrapper = styled.div`
  width: 100%; /* Full width */
  margin: 0 auto;
  background-color: #f4f7fb;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const Content = styled.div`
  font-size: 1.2rem;
  color: #333;
  line-height: 1.8;
`;

const NewsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 1024px) {
    gap: 1.5rem;
  }
`;

const NewsItem = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  transition: transform 0.3s ease;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    gap: 1rem;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

const NewsImage = styled.img`
  max-width: 350px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

const NewsTitle = styled.h2`
  font-size: 1.4rem;
  color: #004080;
  margin: 0;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const NewsDate = styled.p`
  font-size: 1rem;
  color: #777;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const NewsDescription = styled.p`
  font-size: 1.1rem;
  color: #555;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ReadMore = styled.span`
  font-size: 1.1rem;
  color: #004080;
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// Pagination Styles
const PaginationWrapper = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const PaginationIcon = styled.span`
  background-color: #004080;
  color: white;
  padding: 0.6rem;
  font-size: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 0 0.5rem;

  &:hover {
    background-color: #005bb5;
    transform: scale(1.1);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const PageNumber = styled.span`
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
  margin: 0 1rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Ajira = () => {
  const [ajiraData, setAjiraData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    axios
      .get('https://polisi.go.tz:8000/api/announcements/')
      .then((response) => {
        console.log('API Response:', response.data); // Debugging: Log the response
        setAjiraData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching announcements:', error);
        setError('Failed to load announcements.');
        setLoading(false);
      });
  }, []);

  // Pagination Logic
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ajiraData.slice(indexOfFirstItem, indexOfLastItem);

  const renderPaginationNumbers = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(ajiraData.length / itemsPerPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <PageNumber key={i} onClick={() => paginate(i)}>
          {i}
        </PageNumber>
      );
    }
    return pageNumbers;
  };

  if (loading) {
    return <p>Loading announcements...</p>;
  }

  if (error) {
    return <p className="error">{error}</p>;
  }

  return (
    <Section>
      <AjiraWrapper>
        <Title>Ajira</Title>
        <Content>
          <NewsRow>
            {currentItems.map((announcement) => (
              <NewsItem key={announcement.id}>
                {announcement.cover_image && (
                  <NewsImage
                    src={`https://polisi.go.tz:8000${announcement.cover_image}`}
                    alt={announcement.title}
                  />
                )}
                <NewsContent>
                  <NewsTitle>{announcement.title}</NewsTitle>
                  <NewsDate>{new Date(announcement.date_posted).toLocaleDateString()}</NewsDate>
                  <NewsDescription>
                    {announcement.content || 'No content available.'}
                  </NewsDescription>
                  <ReadMore>
                    <a href={announcement.media_file} target="_blank" rel="noopener noreferrer">
                      Read More
                    </a>
                  </ReadMore>
                </NewsContent>
              </NewsItem>
            ))}
          </NewsRow>

          {/* Pagination */}
          <PaginationWrapper>
            <PaginationIcon
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &#8592; {/* Left arrow icon */}
            </PaginationIcon>

            {renderPaginationNumbers()}

            <PaginationIcon
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(ajiraData.length / itemsPerPage)}
            >
              &#8594; {/* Right arrow icon */}
            </PaginationIcon>
          </PaginationWrapper>
        </Content>
      </AjiraWrapper>
    </Section>
  );
};

export default Ajira;

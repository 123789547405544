import React from 'react';
import styled from 'styled-components';
import { FaPhone, FaFax, FaAmbulance, FaEnvelope } from 'react-icons/fa';

// Styled components for Mawasiliano page layout
const Section = styled.section`
  position: relative;
  height: auto;
    width: 100%; /* Full width */
  background-image: 
    repeating-linear-gradient(45deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(135deg, rgba(0, 64, 128, 0.1) 0, rgba(0, 64, 128, 0.1) 1px, transparent 1px, transparent 10px);
  background-color: #e9f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);
`;

const MawasilianoWrapper = styled.div`
    width: 100%; /* Full width */
  margin: 0 auto;
  background-color: #f4f7fb;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const ContactIntro = styled.p`
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

const ContactList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 1024px) {
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const PhoneCard = styled.div`
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 350px;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  color: #004080;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h5`
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CardDetails = styled.p`
  font-size: 1rem;
  color: #555;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const MapContainer = styled.div`
  margin-top: 3rem;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
  }
`;

const Mawasiliano = () => {
  return (
    <Section>
      <MawasilianoWrapper>
        <Title>{/* Use translation here */} Wasiliana nasi</Title>
        <ContactIntro>
          Karibu kwenye ukurasa wetu wa Mawasiliano. Hapa utapata mawasiliano yetu ya muhimu pamoja na njia za kufika kwetu.
        </ContactIntro>
        
        <ContactList>
          {/* Phone Card for Call */}
          <PhoneCard>
            <IconWrapper>
              <FaPhone />
            </IconWrapper>
            <CardTitle>{/* Use translation here */} Tupigie Simu</CardTitle>
            <CardDetails>
              <strong>+25578668306</strong>
            </CardDetails>
            <CardDetails>
              <strong>+0262323585</strong>
            </CardDetails>
          </PhoneCard>

          {/* Phone Card for Fax */}
          <PhoneCard>
            <IconWrapper>
              <FaFax />
            </IconWrapper>
            <CardTitle>Fax</CardTitle>
            <CardDetails>
              <strong>0262323586</strong>
            </CardDetails>
          </PhoneCard>

          {/* Phone Card for Emergency */}
          <PhoneCard>
            <IconWrapper>
              <FaAmbulance />
            </IconWrapper>
            <CardTitle>{/* Use translation here */} Dharura</CardTitle>
            <CardDetails>
              <strong>111 - 112</strong>
            </CardDetails>
          </PhoneCard>

          {/* Phone Card for Email */}
          <PhoneCard>
            <IconWrapper>
              <FaEnvelope />
            </IconWrapper>
            <CardTitle>{/* Use translation here */} Barua pepe</CardTitle>
            <CardDetails>
              <strong>info.phq@tpf.go.tz</strong>
            </CardDetails>
          </PhoneCard>
        </ContactList>

        {/* Map Container */}
        <MapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.7086362044726!2d35.7447378!3d-6.1854314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x184de5d44c821b5d%3A0xaeb3107b7f33d468!2sMAKAO+MAKUU+YA+POLISI+TANZANIA!5e0!3m2!1sen!2s!4v1671800107024!5m2!1sen!2s"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Police Headquarters Tanzania Map"
        ></iframe>
        </MapContainer>
      </MawasilianoWrapper>
    </Section>
  );
};

export default Mawasiliano;
